import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useTypography = ({
  font = "lato",
  variant = "none",
  className,
  ...props
}: Props) => {
  const getTypographyProps = React.useCallback(
    () => ({
      className: cn(styles.typography, styles[font], styles[variant], className),
      style: { ...props },
    }),
    [className, font, props, variant]
  );

  return { getTypographyProps };
};

import React from "react";

import { Props } from "./types";

import Minus from "src/shared/components/icon/assets/icons/minus.svg";
import Plus from "src/shared/components/icon/assets/icons/plus.svg";

export const IconComponent = (props: Props) => {
  let icon = null;

  switch (props.type) {
    case "minus":
      icon = Minus;
      break;
    case "plus":
      icon = Plus;
      break;
  }

  return <img src={icon} alt={props.type} draggable="false" />;
};

import { PayloadAction, Update, createSlice } from "@reduxjs/toolkit";
import { v4 } from "uuid";

import { Bet, Cap } from "src/domain/types";
import { betsAdapter, capsAdapter, oddsAdapter } from "./adapters";
import { capsSelectors } from "./selectors";
import { getCaps, getOdds } from "./utils";
import { ODDS } from "./constants";
import { State } from "./types";

const STATE: State = {
  amount: 0,
  bets: betsAdapter.getInitialState({}),
  caps: capsAdapter.getInitialState({}),
  odds: oddsAdapter.getInitialState({}),
};

export const coreSlice = createSlice({
  name: "core",
  initialState: STATE,
  reducers: {
    amountUpdated: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
    betAdded: (state) => {
      const caps = capsSelectors.selectAll(state);

      const selected = caps.filter((cap) => cap.status === "selected");

      const updates: Array<Update<Cap>> = [];

      selected.forEach((cap) => {
        const update: Update<Cap> = { id: cap.uuid, changes: {} };
        update.changes.status = "none";
        updates.push(update);
      });

      capsAdapter.updateMany(state.caps, updates);

      const amount = state.amount;
      const combinations = selected.map((cap) => cap.index);
      const bet: Bet = { amount, combinations, uuid: v4() };

      betsAdapter.addOne(state.bets, bet);
    },
    betUpdated: (state, action: PayloadAction<Update<Bet>>) => {
      betsAdapter.updateOne(state.bets, action.payload);
    },
    betsUpdated: (state, action: PayloadAction<Array<Update<Bet>>>) => {
      betsAdapter.updateMany(state.bets, action.payload);
    },
    capUpdated: (state, action: PayloadAction<Update<Cap>>) => {
      capsAdapter.updateOne(state.caps, action.payload);
    },
    capsUpdated: (state, action: PayloadAction<Array<Update<Cap>>>) => {
      capsAdapter.updateMany(state.caps, action.payload);
    },
    init: (state) => {
      state.amount = 50;

      capsAdapter.addMany(state.caps, getCaps());
      oddsAdapter.addMany(state.odds, getOdds(ODDS));
    },
  },
});

export const coreActions = {
  amountUpdated: coreSlice.actions.amountUpdated,
  betAdded: coreSlice.actions.betAdded,
  betUpdated: coreSlice.actions.betUpdated,
  betsUpdated: coreSlice.actions.betsUpdated,
  capUpdated: coreSlice.actions.capUpdated,
  capsUpdated: coreSlice.actions.capsUpdated,
  init: coreSlice.actions.init,
};

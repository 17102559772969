import React from "react";

import { useOdds } from "./hooks";

import { Flex } from "src/shared/components";
import { Odd } from "./components";

export const OddsComponent = React.memo(() => {
  const { getOddProps, values } = useOdds();
  return (
    <Flex flexDirection="row" gap="0.75rem" justifyContent="space-between">
      {values.odds.map((odd) => (
        <Odd key={odd.uuid} {...getOddProps(odd)} />
      ))}
    </Flex>
  );
});

OddsComponent.displayName = "OddsComponent";

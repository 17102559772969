import React from "react";
import cn from "classnames";

import { Dice } from "src/shared/components";
import { Props } from "./types";
import { useCap } from "./hooks";

import styles from "./style.module.scss";

const DICES = (dices: [number, number], variant: "default" | "gradient") => [
  <Dice
    key={0}
    className={cn(styles.dice, styles["red"])}
    color="red"
    value={dices[0]}
    variant={variant}
  />,
  <Dice
    key={1}
    className={cn(styles.dice, styles["blue"])}
    color="blue"
    value={dices[1]}
    variant={variant}
  />,
];

export const CapComponent = (props: Props) => {
  const { getCapProps } = useCap(props);

  let content = null;

  switch (props.variant) {
    case "default":
      content = (
        <>
          {DICES(props.cap.dices, "default")}
          <span className={styles.label}>{props.cap.index}</span>
        </>
      );
      break;
    case "gold":
      content = (
        <>
          {DICES(props.cap.dices, "gradient")}
          <span className={styles.label}>{props.cap.index}</span>
        </>
      );
      break;
  }

  return <div {...getCapProps()}>{content}</div>;
};

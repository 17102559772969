import React from "react";

import { Button, Flex } from "src/shared/components";
import { Props } from "./types";

import styles from "./style.module.scss";

export const ButtonControlComponent = (props: Props) => {
  let content = null;

  switch (props.method) {
    case "rand":
      content = (
        <Flex justifyContent="space-between" fullHeight>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
            <span className={styles.square} />
            <span className={styles.square} />
          </Flex>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
            <span className={styles.square} />
            <span className={styles.square} />
          </Flex>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
            <span className={styles.square} />
            <span className={styles.square} />
          </Flex>
        </Flex>
      );
      break;
    case "bias":
      content = (
        <Flex justifyContent="space-between" fullHeight>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
          </Flex>
          <Flex flexDirection="column" justifyContent="center">
            <span className={styles.square} />
          </Flex>
          <Flex flexDirection="column" justifyContent="flex-end">
            <span className={styles.square} />
          </Flex>
        </Flex>
      );
      break;
    case "edge":
      content = (
        <Flex justifyContent="space-between" fullHeight>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
            <span className={styles.square} />
          </Flex>
          <Flex flexDirection="column" justifyContent="space-between">
            <span className={styles.square} />
            <span className={styles.square} />
          </Flex>
        </Flex>
      );
      break;
  }

  return (
    <Button className={styles.button} onClick={props.onClick}>
      {content}
    </Button>
  );
};

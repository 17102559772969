import React from "react";

import { useDispatch } from "react-redux";

import { AppDispatch } from "src/domain/types";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useClickOutsideRef = (callback: () => void) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const cancelHandler = (event: MouseEvent) => {
      const target = event && (event.target as Element);
      if (ref.current && !ref.current.contains(target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", cancelHandler);

    return () => document.removeEventListener("mousedown", cancelHandler);
  }, [callback]);

  return ref;
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { coreActions } from "src/domain/slices";
import { createGetIsAnyCap, getAmount } from "src/domain/selectors";
import { Method } from "src/widgets/panel/model";
import { useAppDispatch } from "src/shared/hooks";

import styles from "./style.module.scss";

export const usePanel = () => {
  const dispatch = useAppDispatch();

  const amount = useSelector(getAmount);

  const isAnySelected = useSelector(createGetIsAnyCap("selected"));

  const getPanelProps = React.useCallback(
    () => ({
      alignItems: "center",
      className: cn(styles.panel, { [styles.active]: isAnySelected }),
      gap: "1.5rem",
      justifyContent: "center",
    }),
    [isAnySelected]
  );

  const getButtonAmountProps = React.useCallback(
    (value: number) => ({
      onClick: () => dispatch(coreActions.amountUpdated(value)),
      selected: amount === value,
    }),
    [amount, dispatch]
  );

  const getButtonBetProps = React.useCallback(
    () => ({
      disabled: !isAnySelected,
      onClick: () => dispatch(coreActions.betAdded()),
    }),
    [dispatch, isAnySelected]
  );

  const [dynamic, setDynamic] = React.useState(1000);

  const getButtonCounterProps = React.useCallback(
    () => ({
      onClick: (method?: Method) => {
        let result = dynamic;

        switch (method) {
          case "+":
            result += 25;
            break;
          case "-":
            result -= 25;
            break;
        }

        setDynamic(result);

        dispatch(coreActions.amountUpdated(result));
      },
      selected: amount === dynamic,
    }),
    [amount, dispatch, dynamic]
  );

  const values = React.useMemo(
    () => ({
      amounts: [50, 100, 250, 500],
      dynamic,
    }),
    [dynamic]
  );

  return {
    getButtonAmountProps,
    getButtonBetProps,
    getButtonCounterProps,
    getPanelProps,
    values,
  };
};

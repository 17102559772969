import React from "react";

import { Cap } from "src/components";
import { Props } from "./types";
import { useButtonCap } from "./hooks";

import styles from "./style.module.scss";

export const ButtonCapComponent = (props: Props) => {
  const { getButtonProps } = useButtonCap(props);
  return (
    <button {...getButtonProps()}>
      <Cap className={styles.cap} cap={props.cap} size="large" variant="default" />
      <Cap className={styles.cap} cap={props.cap} size="large" variant="gold" />
    </button>
  );
};

import random from "lodash/random";
import range from "lodash/range";
import shuffle from "lodash/shuffle";
import take from "lodash/take";

import { EDGE, RAND } from "./constants";
import { Method } from "./types";

/**
 * Генерация случайных позиций
 */
export const getPositions = (method: Method): Array<number> => {
  const result: Array<number> = [];

  switch (method) {
    case "rand":
      result.push(...shuffle(RAND));
      break;
    case "bias": {
      const center = random(1, 36);
      const direction = random(0, 1) ? 5 : 7;

      if (center > 18) {
        result.push(...range(center, 0, -direction));
      } else {
        result.push(...range(center, 37, direction));
      }

      break;
    }
    case "edge":
      result.push(...shuffle(EDGE));
      break;
  }

  const size = random(4, 9);

  return take(result, size);
};

import React from "react";

import { BaseProps } from "src/shared/types";
import { Button, Typography } from "src/shared/components";

import styles from "./style.module.scss";

export const ButtonAmountComponent = (props: BaseProps) => {
  return (
    <Button
      className={styles.button}
      onClick={props.onClick}
      selected={props.selected}
    >
      <Typography font="lato" variant="gold">
        {props.children}
      </Typography>
    </Button>
  );
};

import range from "lodash/range";

/**
 * Все позиции
 */
const RAND: Array<number> = [...range(1, 37)];

/**
 * Крайние позиции
 */
const EDGE: Array<number> = [
  ...range(1, 7),
  ...range(7, 26, 6),
  ...range(12, 31, 6),
  ...range(31, 37),
];

export { EDGE, RAND };

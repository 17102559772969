import React from "react";

import { coreActions } from "src/domain/slices";
import { useAppDispatch } from "src/shared/hooks";

export const useApp = () => {
  const dispatch = useAppDispatch();

  const mountRef = React.useRef(false);

  React.useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;

      dispatch(coreActions.init());
    }
  }, [dispatch]);
};

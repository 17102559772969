import { v4 } from "uuid";

import { Cap, Odd } from "src/domain/types";

/**
 * Генерация коэффициента
 * @param count - количество выбранных исходов
 * @returns - коэффициент
 */
const getOdd = (count: number): Odd => ({
  count,
  rates: [],
  uuid: v4(),
});

/**
 * Генерация набора коэффициентов
 * @param odds - матрица
 * @returns - массив коэффициентов
 */
const getOdds = (odds: Array<Array<number>>) => {
  const result: Array<Odd> = [];

  if (odds.length) {
    for (let i = 0; i < odds.length; i++) {
      const odd = getOdd(i + 1);

      // 1. Добавление максимального коэффициента
      odd.rates.push(odds[i][i]);

      // 2. Добавление остальных коэффициентов
      for (let j = 0; j < odds[i].length; j++) {
        odd.rates.push(odds[j][i]);
      }

      result.push(odd);
    }
  }

  return result;
};

/**
 * Генерация комбинации игральных костей на основе индекса
 */
const getDices = (combination: number) => {
  const first = Math.ceil(combination / 6);
  const second = combination - Math.floor(combination / 6) * 6 || 6;
  return [first, second] as [number, number];
};

/**
 * Генерация комбинации
 * @param index - индекс комбинации
 * @returns - комбинацию
 */
const getCap = (index: number): Cap => ({
  dices: getDices(index),
  index,
  status: "none",
  uuid: v4(),
});

/**
 * Генерация набора комбинаций
 * @returns - массив комбинаций
 */
const getCaps = () => {
  const result: Array<Cap> = [];

  for (let i = 1; i <= 36; i++) {
    const combination = getCap(i);
    result.push(combination);
  }

  return result;
};

export { getCaps, getOdds };

import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useButtonCap = ({ cap, onClick }: Props) => {
  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles.button, {
        [styles.selected]: cap.status === "selected",
      }),
      onClick,
    }),
    [cap, onClick]
  );

  return { getButtonProps };
};

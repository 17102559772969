import React from "react";
import times from "lodash/times";

import { Flex, Grid, Typography } from "src/shared/components";
import { Props } from "./types";
import { useBet } from "./hooks";

import styles from "./style.module.scss";

import Dots from "src/widgets/bets/assets/images/dots.png";

export const BetComponent = (props: Props) => {
  const { getCombinationProps } = useBet(props);
  return (
    <Flex className={styles.bet} flexDirection="column">
      <Grid
        className={styles.combinations}
        gap="0.03125rem"
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows="repeat(6, 1fr)"
      >
        {times(36).map((combination) => (
          <Flex key={combination} {...getCombinationProps(combination + 1)}>
            <Typography font="lato">{combination + 1}</Typography>
          </Flex>
        ))}
      </Grid>
      <img className={styles.dots} src={Dots} draggable="false" />
      <Flex alignItems="center" flex="1 0 auto" justifyContent="center">
        <Typography font="jost" fontSize="1.25rem" variant="gold">
          {props.bet.amount}&nbsp;₽
        </Typography>
      </Flex>
    </Flex>
  );
};

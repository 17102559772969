import { Update } from "@reduxjs/toolkit";

import { AppDispatch, Cap, RootState } from "src/domain/types";
import { coreActions } from "src/domain/slices";
import { createGetCapsByParams, getCaps } from "src/domain/selectors";
import { getPositions } from "./utils";
import { Method } from "./types";

/**
 * Выбор ячейки
 * @param bet - ставка
 */
const onClickCapThunk =
  (cap: Cap) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const selected = createGetCapsByParams(["selected"])(state);

    const update: Update<Cap> = { id: cap.uuid, changes: {} };

    switch (cap.status) {
      case "none":
        if (selected.length < 9) {
          update.changes.status = "selected";
        }
        break;
      case "selected":
        update.changes.status = "none";
        break;
    }

    dispatch(coreActions.capUpdated(update));
  };

/**
 * Очистка ячеек
 */
const onClickClearThunk =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const selected = createGetCapsByParams(["selected"])(state);

    const updates: Array<Update<Cap>> = [];

    selected.forEach((cap) => {
      const update: Update<Cap> = { id: cap.uuid, changes: {} };
      update.changes.status = "none";
      updates.push(update);
    });

    dispatch(coreActions.capsUpdated(updates));
  };

/**
 * Выбор случайных ячеек
 * @param type - метод выбора
 */
const onClickControlThunk =
  (method: Method) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const caps = getCaps(state);

    const updates: Array<Update<Cap>> = [];
    const positions = getPositions(method);

    caps.forEach((cap) => {
      const update: Update<Cap> = { id: cap.uuid, changes: {} };

      update.changes.status = "none";

      if (positions.includes(cap.index)) {
        update.changes.status = "selected";
      }

      updates.push(update);
    });

    dispatch(coreActions.capsUpdated(updates));
  };

export { onClickCapThunk, onClickClearThunk, onClickControlThunk };

const ODDS: Array<Array<number>> = [
  [2.5, 0, 0, 0, 0, 0, 0, 0, 0],
  [1, 3, 0, 0, 0, 0, 0, 0, 0],
  [0, 2, 12, 0, 0, 0, 0, 0, 0],
  [0, 1, 4, 20, 0, 0, 0, 0, 0],
  [0, 0, 2, 5, 150, 0, 0, 0, 0],
  [0, 0, 1, 4, 15, 150, 0, 0, 0],
  [0, 0, 0, 3, 10, 40, 1000, 0, 0],
  [0, 0, 0, 2, 4, 20, 100, 2500, 0],
  [0, 0, 0, 0, 2, 10, 75, 2000, 10000],
];

export { ODDS };

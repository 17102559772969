import { createEntityAdapter } from "@reduxjs/toolkit";

import { Bet, Cap, Odd } from "src/domain/types";

export const betsAdapter = createEntityAdapter<Bet>({
  selectId: (bet) => bet.uuid,
});

export const capsAdapter = createEntityAdapter<Cap>({
  selectId: (cap) => cap.uuid,
});

export const oddsAdapter = createEntityAdapter<Odd>({
  selectId: (odd) => odd.uuid,
});

import React from "react";
import cn from "classnames";

import { Props } from "./types";
import { Method } from "src/widgets/panel/model";

import styles from "./style.module.scss";

export const useButtonCounter = ({ onClick, selected }: Props) => {
  const getButtonLargeProps = React.useCallback(
    () => ({
      className: cn(styles.button, styles.large),
      onClick: () => onClick(),
      selected,
    }),
    [onClick, selected]
  );

  const getButtonSmallProps = React.useCallback(
    (type: Method) => ({
      className: cn(styles.button, styles.small),
      onClick: () => onClick(type),
    }),
    [onClick]
  );

  return { getButtonLargeProps, getButtonSmallProps };
};

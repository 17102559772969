import React from "react";

import { Flex, Typography } from "src/shared/components";
import { Props } from "./types";
import { useOdd } from "./hooks";

import styles from "./style.module.scss";

export const OddComponent = (props: Props) => {
  const { getOddProps, values } = useOdd(props);
  return (
    <Flex {...getOddProps()}>
      <Flex className={styles.round} alignItems="center" justifyContent="center">
        <Typography className={styles.count} font="lato" fontSize="1.13125rem">
          {values.count}
        </Typography>
      </Flex>
      <Typography
        className={styles.rate}
        font="lato"
        fontSize="0.783125rem"
        fontWeight={600}
      >
        {values.rate}
      </Typography>
    </Flex>
  );
};

import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useBet = ({ bet }: Props) => {
  const flexProps = React.useMemo(
    () =>
      ({
        alignItems: "center",
        justifyContent: "center",
      } as React.CSSProperties),
    []
  );

  const getCombinationProps = React.useCallback(
    (combination: number) => ({
      className: cn(styles.combination, {
        [styles.selected]: bet.combinations.includes(combination),
      }),
      ...flexProps,
    }),
    [bet, flexProps]
  );

  return { getCombinationProps };
};

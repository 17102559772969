import React from "react";

import { Bets, Caps, Odds, Panel } from "src/widgets";
import { Flex } from "src/shared/components";
import { useApp } from "./hooks";

import styles from "./style.module.scss";

export const AppComponent = React.memo(() => {
  useApp();
  return (
    <div className={styles.app}>
      <Flex gap="3.75rem" marginLeft="2.25rem">
        <Flex flexDirection="column" width="36.1875rem" />
        <Flex
          alignItems="center"
          flexDirection="column"
          gap="0.75rem"
          justifyContent="center"
        >
          <Odds />
          <Caps />
        </Flex>
        <Bets />
      </Flex>
      <Panel />
    </div>
  );
});

AppComponent.displayName = "AppComponent";

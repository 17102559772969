import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useGrid = ({ className, fullHeight, fullWidth, ...props }: Props) => {
  const getGridProps = React.useCallback(
    () => ({
      className: cn(
        styles.grid,
        {
          [styles.height]: fullHeight,
          [styles.width]: fullWidth,
        },
        className
      ),
      style: { ...props },
    }),
    [className, fullHeight, fullWidth, props]
  );

  return { getGridProps };
};

import React from "react";

import { ButtonCap, ButtonClear, ButtonControl } from "./components";
import { Flex, Grid } from "src/shared/components";
import { useCaps } from "./hooks";

export const CapsComponent = React.memo(() => {
  const { getButtonCapProps, getButtonClearProps, getButtonControlProps, values } =
    useCaps();
  return (
    <Flex flexDirection="column" gap="1rem">
      <Grid
        columnGap="0.02rem"
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows="repeat(6, 1fr)"
        rowGap="0.02rem"
      >
        {values.caps.map((cap) => (
          <ButtonCap key={cap.index} {...getButtonCapProps(cap)} />
        ))}
      </Grid>
      <Flex justifyContent="space-between">
        <ButtonClear {...getButtonClearProps()} />
        <Flex gap="0.735rem">
          <ButtonControl {...getButtonControlProps("rand")} />
          <ButtonControl {...getButtonControlProps("bias")} />
          <ButtonControl {...getButtonControlProps("edge")} />
        </Flex>
      </Flex>
    </Flex>
  );
});

CapsComponent.displayName = "CapsComponent";

import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useDice = ({
  size = "large",
  variant = "default",
  color,
  value,
  className,
}: Props) => {
  const getDiceProps = React.useCallback(
    () => ({
      className: cn(
        styles.dice,
        styles[color],
        styles[variant],
        styles[size],
        styles[`dice-${value}`],
        className
      ),
    }),
    [className, color, size, value, variant]
  );

  return { getDiceProps };
};

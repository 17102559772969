import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useButton = ({
  className,
  disabled,
  onBlur,
  onClick,
  selected,
}: Props) => {
  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles.button, { [styles.selected]: selected }, className),
      disabled,
      onBlur,
      onClick,
    }),
    [className, disabled, onBlur, onClick, selected]
  );

  return { getButtonProps };
};

import React from "react";

import { BaseProps } from "src/shared/types";
import { Button, Typography } from "src/shared/components";

import styles from "./style.module.scss";

export const ButtonControlComponent = (props: BaseProps) => {
  return (
    <Button
      className={styles.button}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Typography font="jost" variant={props.disabled ? "grey" : "gold"}>
        {props.children}
      </Typography>
    </Button>
  );
};

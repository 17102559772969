import React from "react";

import { useSelector } from "react-redux";

import { getBets } from "src/domain/selectors";

export const useBets = () => {
  const bets = useSelector(getBets);

  const amount = bets.reduce((res, bet) => bet.amount + res, 0);

  const values = React.useMemo(
    () => ({
      amount,
      bets,
    }),
    [amount, bets]
  );

  return { values };
};

import React from "react";

import { Flex } from "src/shared/components";
import { usePanel } from "./hooks";
import { ButtonAmount, ButtonBet, ButtonControl, ButtonCounter } from "./components";

export const PanelComponent = () => {
  const {
    getButtonAmountProps,
    getButtonBetProps,
    getButtonCounterProps,
    getPanelProps,
    values,
  } = usePanel();
  return (
    <Flex {...getPanelProps()}>
      <Flex flex="0 0 40%" justifyContent="space-between">
        <div />
        <ButtonControl disabled>ПОВТОРИТЬ</ButtonControl>
      </Flex>
      <Flex flex="0 0 15.5%">
        <ButtonBet {...getButtonBetProps()} />
      </Flex>
      <Flex flex="0 0 40%" justifyContent="space-between">
        <Flex gap="0.75rem">
          {values.amounts.map((amount) => (
            <ButtonAmount key={amount} {...getButtonAmountProps(amount)}>
              {amount}
            </ButtonAmount>
          ))}
        </Flex>
        <ButtonCounter {...getButtonCounterProps()}>{values.dynamic}</ButtonCounter>
      </Flex>
    </Flex>
  );
};

import { configureStore } from "@reduxjs/toolkit";

import { coreSlice } from "./slices";

export const store = configureStore({
  reducer: {
    core: coreSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(),
});

import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useCap = (props: Props) => {
  const getCapProps = React.useCallback(
    () => ({
      className: cn(
        styles.cap,
        styles[props.variant],
        styles[props.size],
        props.className
      ),
    }),
    [props.className, props.size, props.variant]
  );

  return { getCapProps };
};

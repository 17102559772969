import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

export const useOdd = ({ data, selected }: Props) => {
  const [count, rate] = data;

  const flexProps = React.useMemo(
    () =>
      ({
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-around",
      } as React.CSSProperties),
    []
  );

  const getOddProps = React.useCallback(
    () => ({
      className: cn(styles.odd, {
        [styles.selected]: selected,
      }),
      ...flexProps,
    }),
    [flexProps, selected]
  );

  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const values = React.useMemo(
    () => ({
      count,
      rate: `X ${formatter.format(rate)}`,
    }),
    [count, formatter, rate]
  );

  return { getOddProps, values };
};

import React from "react";

import { useSelector } from "react-redux";

import { createGetCapsByParams, getOdds } from "src/domain/selectors";
import { Odd } from "src/domain/types";

export const useOdds = () => {
  const odds = useSelector(getOdds);

  const selected = useSelector(createGetCapsByParams(["selected"]));

  const length = selected.length;

  const getOddProps = React.useCallback(
    (odd: Odd) => ({
      data: [odd.count, odd.rates[length]] as [number, number],
      selected: odd.rates[length] > 0 && length > 0,
    }),
    [length]
  );

  const values = React.useMemo(
    () => ({
      odds,
    }),
    [odds]
  );

  return { getOddProps, values };
};

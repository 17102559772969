import React from "react";

import { Button, Icon, Typography } from "src/shared/components";
import { Props } from "./types";
import { useButtonCounter } from "./hooks";

import styles from "./style.module.scss";

export const ButtonCounterComponent = (props: Props) => {
  const { getButtonLargeProps, getButtonSmallProps } = useButtonCounter(props);
  return (
    <div className={styles.counter}>
      <Button {...getButtonSmallProps("-")}>
        <Icon type="minus" />
      </Button>
      <Button {...getButtonLargeProps()}>
        <Typography font="lato" variant="gold">
          {props.children}
        </Typography>
      </Button>
      <Button {...getButtonSmallProps("+")}>
        <Icon type="plus" />
      </Button>
    </div>
  );
};

import React from "react";

import { BaseProps } from "src/shared/types";

import styles from "./style.module.scss";

export const ButtonBetComponent = (props: BaseProps) => {
  return (
    <button
      className={styles.button}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

import { createSelector } from "reselect";

import { betsSelectors, capsSelectors, oddsSelectors } from "src/domain/slices";
import { Cap, RootState } from "src/domain/types";

/**
 * Селекторы хранилища ядра
 */

/**
 * core
 * @param state - хранилище
 * @returns хранилище core
 */
const getRoot = (state: RootState) => state.core;

/**
 * Селектор номинала
 */
export const getAmount = createSelector([getRoot], (root) => root.amount);

/**
 * Селектор ставок
 */
export const getBets = createSelector([getRoot], (root) =>
  betsSelectors.selectAll(root)
);

/**
 * Селектор комбинаций
 */
export const getCaps = createSelector([getRoot], (root) =>
  capsSelectors.selectAll(root)
);

/**
 * Селектор коэффициентов
 */
export const getOdds = createSelector([getRoot], (root) =>
  oddsSelectors.selectAll(root)
);

/**
 * Генератор селектора поиска комбинаций по параметрам
 * @param statuses - список статусов комбинаций
 * @returns - массив ставок
 */
export const createGetCapsByParams = (statuses: Array<Cap["status"]>) =>
  createSelector([getCaps], (caps) => {
    let result = [...caps];

    if (statuses.length) {
      result = result.filter((cap) => statuses.includes(cap.status));
    }

    return result;
  });

/**
 * Генератор селектора проверки наличия комбинации по статусу
 * @param status - статус
 * @returns - флаг наличия
 */
export const createGetIsAnyCap = (status: Cap["status"]) =>
  createSelector([getCaps], (caps) => caps.some((cap) => cap.status === status));

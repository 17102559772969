import React from "react";

import { Props } from "./types";
import { useDice } from "./hooks";

export const DiceComponent = (props: Props) => {
  const { getDiceProps } = useDice(props);

  let content = null;

  switch (props.value) {
    case 1:
      content = <span />;
      break;
    case 2:
      content = (
        <>
          <span />
          <span />
        </>
      );
      break;
    case 3:
      content = (
        <>
          <span />
          <span />
          <span />
        </>
      );
      break;
    case 4:
      content = (
        <>
          <div>
            <span />
            <span />
          </div>
          <div>
            <span />
            <span />
          </div>
        </>
      );
      break;
    case 5:
      content = (
        <>
          <div>
            <span />
            <span />
          </div>
          <div>
            <span />
          </div>
          <div>
            <span />
            <span />
          </div>
        </>
      );
      break;
    case 6:
      content = (
        <>
          <div>
            <span />
            <span />
            <span />
          </div>
          <div>
            <span />
            <span />
            <span />
          </div>
        </>
      );
      break;
  }

  return <div {...getDiceProps()}>{content}</div>;
};

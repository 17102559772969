import React from "react";

import { useSelector } from "react-redux";

import { Cap } from "src/domain/types";
import { createGetIsAnyCap, getCaps } from "src/domain/selectors";
import {
  Method,
  onClickCapThunk,
  onClickClearThunk,
  onClickControlThunk,
} from "src/widgets/caps/model";
import { useAppDispatch } from "src/shared/hooks";

export const useCaps = () => {
  const dispatch = useAppDispatch();

  const caps = useSelector(getCaps);

  const isAnySelected = useSelector(createGetIsAnyCap("selected"));

  const getButtonCapProps = React.useCallback(
    (cap: Cap) => ({
      cap,
      onClick: () => dispatch(onClickCapThunk(cap)),
    }),
    [dispatch]
  );

  const getButtonClearProps = React.useCallback(
    () => ({
      onClick: () => dispatch(onClickClearThunk()),
      disabled: !isAnySelected,
    }),
    [dispatch, isAnySelected]
  );

  const getButtonControlProps = React.useCallback(
    (method: Method) => ({
      onClick: () => dispatch(onClickControlThunk(method)),
      disabled: !isAnySelected,
      method,
    }),
    [dispatch, isAnySelected]
  );

  const values = React.useMemo(
    () => ({
      caps,
    }),
    [caps]
  );

  return {
    getButtonCapProps,
    getButtonClearProps,
    getButtonControlProps,
    values,
  };
};

import React from "react";

import { Bet } from "./components";
import { Flex, Grid, Typography } from "src/shared/components";
import { useBets } from "./hooks";

import styles from "./style.module.scss";

export const BetsComponent = () => {
  const { values } = useBets();
  return (
    <Flex flexDirection="column" gap="1.125rem">
      <Flex alignItems="center">
        <Typography font="jost" fontSize="1.75rem" variant="gold">
          МОИ СТАВКИ · {values.bets.length} · {values.amount} ₽{" "}
        </Typography>
      </Flex>
      <Grid
        className={styles.tickets}
        columnGap="1.75rem"
        gridAutoColumns="16.625rem"
        gridAutoFlow="column"
        gridTemplateRows="repeat(2, 1fr)"
        rowGap="1.75rem"
      >
        {values.bets.map((bet) => (
          <Bet key={bet.uuid} bet={bet} />
        ))}
      </Grid>
    </Flex>
  );
};
